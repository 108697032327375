<div class="main">
    <div class="sub-main" [ngStyle]="{height: showSignupForm && !showCard ? '100vh': '100%'}">
        <div class="logo-container">
            <img src="assets/images/web/orgNowcast.png" alt="logo">
        </div>
        <div class="app-content-inner" *ngIf="showPlans">
            <div class="app-block app-block-padding" style="background: #f3f9ff;">

                <p class="info-title d-flex justify-content-center" style="color: #6e6d6d;font-size: 40px;">Choose your
                    plan</p>

                <div class="d-flex justify-content-between plan-wrapper mt-5">
                    <div class="subscription-block" [class.features]="basicFeature"
                        (click)="activePlan ='Basic'" [ngClass]="{active: activePlan === 'Basic'}">
                        <div class="plan-name pb-2">
                            <p>Basic</p>
                            <!-- class="mx-4 px-3"    -->
                            <h2><span>$</span>{{planAmountBasic}}</h2>
                        </div>

                        <p style="text-align: center;">Per month</p>
                        <!-- class="para-text mx-5 my-0" -->
                        <!-- <div class="app-filters d-flex justify-content-center align-items-center">
                            <button *ngIf="!basicFeature" (click)="showBasic()" class="btn btn-white">Show Details</button>
                            <button *ngIf="basicFeature" (click)="showBasic()" class="btn btn-white">Hide Details</button>
                        </div> -->
                        <div class="plan-feature">
                            <!-- <p>500GB Cloud Storage (VOD)</p> -->

                        <p>{{totalDiskSpaceBasic < this.TBData ? totalDiskSpaceBasicGB + ' GB' : totalDiskSpaceBasicTB + ' TB'}}
                            Cloud Storage (VOD)</p>
                            <ul style="margin-bottom: 0px;" *ngFor="let item of basicFeature">
                                <div class="d-flex align-items-center">
                                    <i class="fa fa-check " aria-hidden="true"></i>
                                    <li *ngIf="item.isView">{{item.name}}</li>
                                </div>

                            </ul>
                        </div>
                        <button *ngIf="basicTrialDays>0" [ngClass]="{disable: activePlan !== 'Basic'}"
                            [disabled]="activePlan !== 'Basic'" class="trial-btn"
                            (click)="this.showSignupForm=true;this.showPlans=false;">Try {{basicTrialDays}} days
                        </button>
                        <button *ngIf="basicTrialDays==0" [ngClass]="{disable: activePlan !== 'Basic'}"
                            [disabled]="activePlan !== 'Basic'" class="trial-btn"
                            (click)="this.showSignupForm=true;this.showPlans=false;">Get started
                        </button>
                    </div>
                    <div class="subscription-block" [class.features]="standardFeature"
                        (click)="activePlan = 'Standard'" [ngClass]="{active: activePlan === 'Standard'}">
                        <div class="plan-name pb-2">
                            <p>Standard</p>
                            <!-- class="mx-4" -->
                            <h2><span>$</span>{{planAmountStandard}}</h2>
                        </div>
                        <p style="text-align: center;">Per month</p>
                        <!-- class="para-text mx-5 my-0" -->
                        <!-- <div class="app-filters d-flex justify-content-center align-items-center">
                            <button *ngIf="!standardFeature" (click)="showStandard()" class="btn btn-white">Show Details</button>
                            <button *ngIf="standardFeature" (click)="showStandard()" class="btn btn-white">Hide Details</button>
                        </div> -->
                        <div class="plan-feature">
                            <!-- <p>1TB Cloud Storage (VOD)</p> -->
                        <p>{{totalDiskSpaceStandard < this.TBData ? totalDiskSpaceStandardGB + ' GB' : totalDiskSpaceStandardTB + ' TB'}}
                            Cloud Storage (VOD)</p>
                            <ul style="margin-bottom: 0px;" *ngFor="let item of standardFeature">
                                <div class="d-flex align-items-center">
                                    <i class="fa fa-check " aria-hidden="true"></i>
                                    <li *ngIf="item.isView">{{item.name}}</li>
                                </div>
                            </ul>
                        </div>
                        <button class="trial-btn" [ngClass]="{disable: activePlan !== 'Standard'}"
                            [disabled]="activePlan !== 'Standard'" *ngIf="standardTrialDays>0"
                            (click)="this.showSignupForm=true;this.showPlans=false;">Try {{standardTrialDays}}
                            days</button>
                        <button class="trial-btn" [ngClass]="{disable: activePlan !== 'Standard'}"
                            [disabled]="activePlan !== 'Standard'" *ngIf="standardTrialDays==0"
                            (click)="this.showSignupForm=true;this.showPlans=false;">Get started</button>

                    </div>
                    <div class="subscription-block" [class.features]="premiumFeature" (click)="activePlan = 'Premium'"
                        [ngClass]="{active: activePlan === 'Premium'}">
                        <div class="plan-name pb-2">
                            <p>Premium</p>
                            <h2><span>$</span>{{planAmountPremium}}</h2>
                        </div>

                        <p style="text-align: center;">Per month</p>
                        <!-- class="para-text mx-5 my-0" -->
                        <!-- <div class="app-filters d-flex justify-content-center align-items-center">
                            <button *ngIf="!premiumFeature" (click)="showPremium()" class="btn btn-white">Show Details</button>
                            <button *ngIf="premiumFeature" (click)="showPremium()" class="btn btn-white">Hide Details </button>
                        </div> -->
                        <div class="plan-feature">
                            <!-- <p>5TB Cloud Storage (VOD)</p> -->
                    <p>{{totalDiskSpacePremium < this.TBData ? totalDiskSpacePremiumGB + ' GB' : totalDiskSpacePremiumTB + ' TB'}}
                        Cloud Storage (VOD)</p>
                            <ul style="margin-bottom: 0px;" *ngFor="let item of premiumFeature">
                                <div class="d-flex align-items-center">
                                    <i class="fa fa-check " aria-hidden="true"></i>
                                    <li *ngIf="item.isView">{{item.name}}</li>
                                </div>
                            </ul>
                        </div>
                        <button class="trial-btn" [ngClass]="{disable: activePlan !== 'Premium'}"
                            [disabled]="activePlan !== 'Premium'" *ngIf="premiumTrialDays>0"
                            (click)="this.showSignupForm=true;this.showPlans=false;">Try {{premiumTrialDays}}
                            days</button>
                        <button class="trial-btn" [ngClass]="{disable: activePlan !== 'Premium'}"
                            [disabled]="activePlan !== 'Premium'" *ngIf="premiumTrialDays==0"
                            (click)="this.showSignupForm=true;this.showPlans=false;">Get started</button>

                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center mt-5">
                    <!-- <button class="btn btn-blue mb-0" style="padding: 10px 15px;text-transform: uppercase;" (click)="this.showSignupForm=true;this.showPlans=false;">Get Started</button> -->
                    <!-- <button class="btn btn-white mr-10" >Cancel</button> -->
                </div>
            </div>

        </div>


        <div class="content-wrap" *ngIf="showSignupForm">
            <div class="content">
                <form #signUpForm="ngForm" (ngSubmit)="signUpUser()">
                    <!-- <div class="logo-container" *ngIf="signUpLogo">
                        <img [src]="signUpLogo" alt="logo">
                    </div> -->
                    <div class="app-content-inner">
                        <div class="">
                            <div class="row">
                                <p class="info-title">Basic Information</p>
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>Organization Name</label>
                                        <input type="text" class="form-control" maxlength="50"
                                            [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.organizationName"
                                            name="organizationName" required #organizationName="ngModel"
                                            appWhiteSpaceValidator>
                                        <ng-container
                                            *ngIf="organizationName.errors && (organizationName.touched || organizationName.dirty)">
                                            <p class="error"
                                                [hidden]="!organizationName.errors.required && !organizationName.errors.whiteSpaceValidator">
                                                Organization Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>First Name</label>
                                        <input type="text" class="form-control" maxlength="50"
                                            [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.firstName"
                                            name="firstName" required #firstName="ngModel" appWhiteSpaceValidator>
                                        <ng-container
                                            *ngIf="firstName.errors && (firstName.touched || firstName.dirty)">
                                            <p class="error"
                                                [hidden]="!firstName.errors.required && !firstName.errors.whiteSpaceValidator">
                                                First Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>Last Name</label>
                                        <input type="text" class="form-control" maxlength="50"
                                            [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.lastName"
                                            name="lastName" required #lastName="ngModel" appWhiteSpaceValidator>
                                        <ng-container *ngIf="lastName.errors && (lastName.touched || lastName.dirty)">
                                            <p class="error"
                                                [hidden]="!lastName.errors.required && !lastName.errors.whiteSpaceValidator">
                                                Last Name is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>

                                <!-- </div> -->
                                <!-- <div class="row"> -->
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>Email</label>
                                        <input type="text" class="form-control"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            [ngClass]="{disable: !disableFields}" [(ngModel)]="
                                            signUp.email" name="email" required #email="ngModel">
                                        <ng-container *ngIf="email.errors && (email.touched || email.dirty)">
                                            <p class="error" [hidden]="!email.errors.pattern">Please enter a valid email
                                            </p>
                                            <p class="error" [hidden]="!email.errors.required">Email is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group required">
                                        <label>Mobile</label>
                                        <input type="text" class="form-control" pattern="^[0-9]*$"
                                            [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.mobileNumber"
                                            name="mobileNumber" required #mobileNumber="ngModel">
                                        <ng-container
                                            *ngIf="mobileNumber.errors && (mobileNumber.touched || mobileNumber.dirty)">
                                            <p class="error" [hidden]="!mobileNumber.errors.pattern">Please enter a
                                                valid
                                                number</p>
                                            <p class="error" [hidden]="!mobileNumber.errors.required">Mobile is required
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group required" style="position: relative;">
                                        <label>Password</label>
                                        <!-- <input type="password" class="form-control" [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.password" name="password"
                                            required #password="ngModel" appWhiteSpaceValidator>
                                        <ng-container *ngIf="password.errors && (password.touched || password.dirty)">
                                            <p class="error"
                                                [hidden]="!password.errors.required && !password.errors.whiteSpaceValidator">
                                                password is required
                                            </p>
                                        </ng-container> -->


                                        <input [type]="passwordType ? 'text' : 'password'" required autocomplete="off"
                                            [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.password"
                                            name="password" #password="ngModel" appWhiteSpaceValidator
                                            autocomplete="nope" class="form-control">
                                        <em class="password-icon eye-icon" [class.view]="passwordType"
                                            *ngIf="!passwordType" [appSvgIcon]="'crossEyeIcon'"
                                            (click)="passwordType = !passwordType"></em>
                                        <em class="password-icon eye-icon" [class.view]="passwordType"
                                            *ngIf="passwordType" [appSvgIcon]="'EyeIcon'"
                                            (click)="passwordType = !passwordType"></em>
                                    </div>
                                    <ng-container *ngIf="password.errors && (password.touched || password.dirty)">
                                        <p class="error"
                                            [hidden]="!password.errors.required && !password.errors.whiteSpaceValidator">
                                            password is required
                                        </p>
                                    </ng-container>
                                </div>
                                <div class="col-sm-6">
                                    <!-- <label class="label">Organization type</label>
                                    <select name="organizationType">

                                    </select> -->
                                    <div class="form-group required">
                                        <label>Organization type</label>
                                        <span class="app-dropdown d-block m-0">
                                            <select name="organizationType" [ngClass]="{disable: !disableFields}"
                                                [(ngModel)]="signUp.profit" class="form-control">
                                                <option [value]="false">Non-profit</option>
                                                <option [value]="true">profit</option>
                                            </select>
                                        </span>
                                        <!-- <p-dropdown [options]="profitOption" style="padding: 0 !important;" [ngClass]="{disable: !disableFields}" [(ngModel)]="signUp.profit" name="organizationType" (onChange)="selectOrgType($event)" class="form-control" optionLabel="name"></p-dropdown> -->
                                    </div>
                                    <!-- <div class="radio-group mb-0">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" [(ngModel)]="signUp.profit" name="profit" #profit="ngModel" [value]=false
                                                id="flexRadioDefault1" checked >
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Non-profit
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" [(ngModel)]="signUp.profit" name="profit" #profit="ngModel" [value]=true
                                                 id="flexRadioDefault2" >
                                            <label  class="form-check-label label" for="flexRadioDefault2">
                                                Profit
                                            </label>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-sm-6 d-flex align-items-center">
                                    <div class="form-check checkbox-container  mt-3">
                                        <input class="form-check-input" id="isTermAndCondition-checkbox" type="checkbox"
                                            value="" [checked]="signUp.isTermAndCondition"
                                            [(ngModel)]="signUp.isTermAndCondition" name="isTermAndCondition"
                                            #isTermAndCondition="ngModel">
                                        <label for="isTermAndCondition-checkbox" class="checkmark"></label>
                                        <label style="color: #fff;" class="form-check-label">
                                            By clicking, you agree to our
                                        </label>
                                        <a style="margin-left: 6px;" href="https://www.nowcast.cc/terms-of-conditions"
                                            target="_blank">Terms of Service</a><span
                                            style="color: #fff;">&nbsp;and&nbsp;</span> <a
                                            href="https://www.nowcast.cc/privacy-policy" target="_blank">Privacy
                                            Policy</a>

                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group required">
                                            <label>Subscription</label>
                                            <span class="app-dropdown d-block m-0">
                                                <select class="form-control" [(ngModel)]="signUp.subscriptionPlanId"
                                                    name="subscriptionPlanId" #subscriptionPlanId="ngModel" required>
                                                    <option value="">Select</option>
                                                    <option value="1">Basic</option>
                                                    <option value="2">Standard</option>
                                                    <option value="3">Premium</option>
                                                </select>
                                            </span>
                                            <ng-container
                                                *ngIf="subscriptionPlanId.errors && (subscriptionPlanId.touched || subscriptionPlanId.dirty)">
                                                <p class="error" [hidden]="!subscriptionPlanId.errors.required">Subscription
                                                    is
                                                    required</p>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div> -->
                        </div>

                        <!-- <div class="app-block app-block-padding mt-20">
                            <p class="info-title mt-2">Organization</p>
                          
                                    <label class="label">Select type of Organization</label>
                                    <div class="radio-group mb-0">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" [(ngModel)]="signUp.profit" name="profit" #profit="ngModel" [value]=false
                                                id="flexRadioDefault1" checked >
                                            <label class="form-check-label" for="flexRadioDefault1">
                                                Non-profit
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" [(ngModel)]="signUp.profit" name="profit" #profit="ngModel" [value]=true
                                                 id="flexRadioDefault2" >
                                            <label  class="form-check-label label" for="flexRadioDefault2">
                                                Profit
                                            </label>
                                        </div>
                                    </div>
                                    <!-- <div class="form-check mt-2">
                                        <input class="form-check-input" type="checkbox" value=""  [checked]="signUp.isTermAndCondition" [(ngModel)]="signUp.isTermAndCondition" name="isTermAndCondition" #isTermAndCondition="ngModel" >
                                        <label style="color: #656565;" class="form-check-label" >
                                            I have read and agree to the 
                                        </label>
                                        <a style="margin-left: 6px;" href="https://www.nowcast.cc/terms-of-conditions" target="_blank">Nowcast Terms and Conditions</a>
              
                                    </div>
                          </div> -->

                        <div class="container text-center mt-20" *ngIf="!showCard">
                            <button type="submit" class="btn btn-blue mr-10"
                                [disabled]="!signUpForm.valid  || !signUp.isTermAndCondition ">Continue</button>
                            <button type="button" class="btn btn-white mr-10 cancel-btn"
                                (click)="onCloseButtonClick()">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="app-content-inner" *ngIf="showCard">
            <div class="cardDetail mt-20">
                <div class="">
                    <p class="info-title">Make Payment</p>
                    <div class="form-group mt-0 login-stripe">
                        <label for="">Card details </label>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <!-- <label>Card holder name</label> -->
                                    <input type="text" maxlength="50" class="form-control card-form"
                                        placeholder="Name on Card" [(ngModel)]="cardHolderName" name="holdername"
                                        #holdername="ngModel" required>
                                    <ng-container *ngIf="holdername.errors && (submitted)">
                                        <p class="error" [hidden]="!holdername.errors.required">
                                            Name is required</p>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <!-- <label>Email</label> -->
                                    <input type="text" class="form-control card-form" placeholder="Card holder email"
                                        [(ngModel)]="cardHolderEmail" name="email" #email="ngModel" required
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                                    <ng-container *ngIf="email.errors && (submitted)">
                                        <p class="error" [hidden]="!email.errors.pattern && !email.errors.required">
                                            Please enter a valid email</p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <ngx-stripe-card [options]="cardOptions"
                                    [elementsOptions]="elementsOptions"></ngx-stripe-card>
                                <ng-container *ngIf="cardError.error">
                                    <p class="error">
                                        {{cardError.message}}</p>
                                </ng-container>
                            </div>
                            <div class="col-md-6">
                                <div class="card-container">
                                    <img src="assets/images/media/pngegg.png" alt="cards">
                                </div>
                            </div>
                        </div>

                        <div class="row mt-10">
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <!-- <label>Card holder name</label> -->
                                    <input type="text" maxlength="255" class="form-control card-form"
                                        placeholder="Address Line 1" [(ngModel)]="cardHolderAddressLine1"
                                        name="addressline1" #addressline1="ngModel" required>
                                    <ng-container *ngIf="addressline1.errors && (submitted)">
                                        <p class="error" [hidden]="!addressline1.errors.required">
                                            Address is required</p>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group required">
                                    <!-- <label>Card holder name</label> -->
                                    <input type="text" maxlength="255" class="form-control card-form"
                                        placeholder="Address Line 2" [(ngModel)]="cardHolderAddressLine2"
                                        name="addressline2" #addressline2="ngModel" required>
                                    <!-- <ng-container *ngIf="lastName.errors && (submitted)">
                                        <p class="error" [hidden]="!lastName.errors.required">
                                            Last Name is required</p>
                                    </ng-container> -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="row">
                            <div class="col-md-12">
                                <div class="form-group required"> -->
                                    <!-- <label>Card holder name</label> -->
                                    <!-- <input type="text" maxlength="255" class="form-control card-form"
                                        placeholder="Address Line 2" [(ngModel)]="cardHolderAddressLine2"
                                        name="addressline2" #addressline2="ngModel" required> -->
                                    <!-- <ng-container *ngIf="lastName.errors && (submitted)">
                                        <p class="error" [hidden]="!lastName.errors.required">
                                            Last Name is required</p>
                                    </ng-container> -->
                                <!-- </div>
                            </div>
                        </div> -->

                        <!-- <div class="row">
                            <div class="col-md-12">
                                <div class="form-group required">
                                    <input type="text" class="form-control card-form" placeholder="City"
                                        [(ngModel)]="cardHolderCity" name="city" #city="ngModel" required>
                                    <ng-container *ngIf="city.errors && (submitted)">
                                        <p class="error" [hidden]="!city.errors.required">
                                            City is required</p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group required">
                                    <input type="text" class="form-control card-form" placeholder="Country"
                                        [(ngModel)]="cardHolderCountry" name="country" #country="ngModel" required>
                                    <ng-container *ngIf="country.errors && (submitted)">
                                        <p class="error" [hidden]="!country.errors.required">
                                            Country is required</p>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group required">
                                    <input type="text" class="form-control card-form" placeholder="State"
                                        [(ngModel)]="cardHolderState" name="state" #state="ngModel" required>
                                    <ng-container *ngIf="state.errors && (submitted)">
                                        <p class="error" [hidden]="!state.errors.required">
                                            State is required</p>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group required">
                                    <input type="text" class="form-control card-form" placeholder="Zipcode" (keypress)="isNumberOnly($event)"
                                        [(ngModel)]="cardHolderZipcode" name="zipcode" #zipcode="ngModel" required>
                                    <ng-container *ngIf="zipcode.errors && (submitted)">
                                        <p class="error" [hidden]="!zipcode.errors.required">
                                            Zip Code is required</p>
                                    </ng-container>
                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <ng-container *ngIf="countryList.length;else countryInput">
                                        <span class="app-dropdown d-block m-0 input-width">
                                            <select class="form-control card-form" placeholder="Select Country"
                                                [(ngModel)]="cardHolderCountry" name="country" required
                                                #country="ngModel" (change)="updateStateList($event)">
                                                <option selected disabled value="">Select Country</option>
                                                <option *ngFor="let ct of countryList" value="{{ct.code2}}">
                                                    {{ct.name}}
                                                </option>
                                            </select>
                                            <ng-container *ngIf="country.errors && (submitted)">
                                                <p class="error" [hidden]="!country.errors.required">
                                                    Country is required</p>
                                            </ng-container>
                                        </span>
                                    </ng-container>
                                    <ng-template #countryInput>
                                        <input type="text" class="form-control card-form" placeholder="Country"
                                            [(ngModel)]="cardHolderCountry" maxlength="25" readonly
                                            [ngModelOptions]="{standalone: true}" name="country" #country="ngModel"
                                            required>
                                        <ng-container *ngIf="country.errors && (submitted)">
                                            <p class="error" [hidden]="!country.errors.required">
                                                Country is required</p>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <ng-container *ngIf="stateList.length;else stateInput">
                                        <span class="app-dropdown d-block m-0 input-width">
                                            <select class="form-control card-form" placeholder="Select State"
                                                [(ngModel)]="cardHolderState" name="state" required #state="ngModel"
                                                (change)="updateCityList($event)">
                                                <option selected disabled value="">Select State</option>
                                                <option *ngFor="let ct of stateList" value="{{ct.name}}">
                                                    {{ct.name}}
                                                </option>
                                            </select>
                                            <ng-container *ngIf="state.errors && (submitted)">
                                                <p class="error" [hidden]="!state.errors.required">
                                                    State is required</p>
                                            </ng-container>
                                        </span>
                                    </ng-container>
                                    <ng-template #stateInput>
                                        <input type="text" class="form-control card-form" placeholder="State"
                                            [(ngModel)]="cardHolderState" maxlength="25"
                                            [ngModelOptions]="{standalone: true}" name="state" #state="ngModel"
                                            required>
                                        <ng-container *ngIf="state.errors && (submitted)">
                                            <p class="error" [hidden]="!state.errors.required">
                                                State is required</p>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <ng-container *ngIf="cityList.length;else cityInput">
                                        <span class="app-dropdown d-block m-0 input-width">
                                            <select class="form-control card-form" placeholder="Select City"
                                                [(ngModel)]="cardHolderCity" name="state" required #city="ngModel">
                                                <option selected disabled value="">Select City</option>
                                                <option *ngFor="let ct of cityList" value="{{ct.cityName}}">
                                                    {{ct.cityName}}
                                                </option>
                                            </select>
                                            <ng-container *ngIf="city.errors && (submitted)">
                                                <p class="error" [hidden]="!city.errors.required">
                                                    City is required</p>
                                            </ng-container>
                                        </span>
                                    </ng-container>
                                    <ng-template #cityInput>
                                        <input type="text" class="form-control card-form" placeholder="City"
                                            maxlength="25" [(ngModel)]="cardHolderCity" pattern="^[a-zA-Z ]*$"
                                            appWhiteSpaceValidator [ngModelOptions]="{standalone: true}" name="city"
                                            #city="ngModel" required>
                                        <ng-container *ngIf="city.errors && (submitted)">
                                            <p class="error" [hidden]="!city.errors.pattern">Please enter a valid city
                                            </p>
                                            <p class="error" [hidden]="!city.errors.required">
                                                City is required</p>
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="form-group required">
                                    <input type="text" class="form-control card-form" placeholder="Zipcode" maxlength="12" minlength="3"
                                        (keypress)="isNumberOnly($event)" [(ngModel)]="cardHolderZipcode" name="zipcode"
                                        #zipcode="ngModel" required>
                                    <ng-container *ngIf="zipcode.errors && (submitted)">
                                        <p class="error" [hidden]="!zipcode.errors.required">
                                            Zip Code is required</p>
                                    </ng-container>
                                    <!-- <ng-container *ngIf="((cardHolderZipcode.toString().trim() !== '' && (cardHolderZipcode.toString().length < 5
                                            || cardHolderZipcode.toString().length > 9 ))) && submitted ">
                                                <p class="error">
                                                    Zip Code should be between 5 to 9 digits</p>
                                            </ng-container> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container text-center mt-20">
                <button type="submit" class="btn btn-blue mr-10" [disabled]="this.disableBtn"
                    (click)="createToken()">Pay</button>
                <button type="submit" (click)="showCard = false;disableFields=true"
                    class="btn btn-blue mr-10">Cancel</button>
            </div>
        </div>

    </div>
</div>